import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
    CenteredQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const FortschreitendeKultur = ({ data }) => (
    <InnerPage title="Fortschreitende Kultur" description="">
        <IntroHolder
            title="Fortschreitende Kultur"
            subtitle="Kulturelle Weiterentwicklung verlangt spirituelle und gesellschaftliche Entwicklung"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Gott und der Mensch',
                'current-item': 'Fortschreitende Kultur',
            }}
        >
            <IntroQuote href="/woran-bahai-glauben/gott-und-der-mensch/fortschreitende-kultur/zitate">
                Alle Menschen wurden erschaffen, eine ständig fortschreitende
                Kultur voranzutragen.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Die Menschheit, so erklären die Bahá’í-Schriften bildlich,
                    hat bisher die Stufe ihrer Kindheit durchlebt und steht nun
                    an der Schwelle zu ihrem Reifezeitalter.
                </p>
                <div>
                    <p>
                        Die tiefgreifenden Umbrüche unserer Zeit sind
                        Kennzeichen des Wandels – einer Zeit, die mit dem
                        Erwachsenwerden verglichen werden kann. In dieser Phase
                        werden Gedanken, Einstellungen und Gewohnheiten aus
                        früheren Entwicklungsstufen der Menschheit verändert.
                        Neue Handlungs- und Denkmuster für die zukünftige
                        Reifezeit werden nach und nach etabliert. „Was den
                        Bedürfnissen des Menschen in seiner früheren Geschichte
                        angemessen war, ist weder passend noch genügend für die
                        Erfordernisse des heutigen Tages, dieser Zeit des Neuen,
                        der Vollendung …“, erklärt ‘Abdu’l-Bahá und fährt fort:
                    </p>
                </div>
                <CenteredQuote referenceNumber="2">
                    Der Mensch muss mit neuen Tugenden und Kräften, neuen
                    sittlichen Maßstäben und Fähigkeiten erfüllt werden.
                </CenteredQuote>
                <div>
                    <p>
                        Das Kennzeichen dieses herannahenden Zeitalters der
                        Reife ist die Vereinigung der Menschheit. Während in der
                        Vergangenheit die Einheit der{' '}
                        <Link to="/gemeinsames-handeln/familie-und-kinder/">
                            Familie
                        </Link>
                        , des Stammes, des Stadtstaates und der Nation im
                        Mittelpunkt standen, stehen wir heute vor der
                        Herausforderung der Einheit der ganzen Menschheit.
                    </p>
                </div>
                <div>
                    <p>
                        Das Streben nach einer weltumspannenden Kultur, die
                        materiellen und geistigen Wohlstand in sich vereint,
                        verlangt nach einer gemeinsamen Entwicklung von
                        geistig-spirituellen wie auch gesellschaftlichen
                        Aspekten des Lebens. Durch das Zusammenwirken von Glaube
                        und Vernunft wird es möglich, die Fähigkeiten und
                        Kräfte, die sowohl im Einzelnen als auch in der
                        Menschheit als Ganzem verborgen liegen, zu entfalten und
                        umzusetzen. Wissenschaft und Religion sollten gemeinsam
                        zum Wohlergehen für alle Bewohner der Erde beitragen.
                    </p>
                </div>
                <div>
                    <p>
                        ‘Abdu’l-Bahá schreibt, „dass die materielle Zivilisation
                        zwar ein Mittel zum Fortschritt der Menschenwelt ist,
                        dass jedoch der gewünschte Erfolg – das Glück der
                        Menschheit – erst dann zu erreichen ist, wenn die
                        materielle Zivilisation mit der göttlichen Kultur
                        vereinigt wird.” Er fährt fort: „Die materielle
                        Zivilisation ist wie das Glas um die Lampe, die
                        göttliche Kultur ist die Lampe selbst. Das Glas ohne
                        Licht ist dunkel. Die materielle Zivilisation ist wie
                        der Leib. Sei er auch noch so anmutig, elegant und
                        schön, so ist er dennoch tot. Die göttliche Kultur ist
                        wie der Geist; der Leib erhält sein Leben durch den
                        Geist, sonst ist er ein Leichnam. So ist es klar, dass
                        die Menschenwelt den Odem des Heiligen Geistes braucht.
                        Ohne den Geist ist die Menschenwelt leblos; ohne dieses
                        Licht verbleibt die Menschenwelt in tiefster
                        Finsternis.”
                        <sup>3</sup>
                    </p>
                </div>
                <Reference>
                    <p>1. Baha’u’llah, Ährenlese 109:2</p>
                    <p>
                        2. ‘Abdu’l-Bahá zitiert in: Shoghi Effendi, Der
                        verheißene Tag ist gekommen 31:4
                    </p>
                    <p>3. ‘Abdu’l-Bahá, Briefe und Botschaften 227:22</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default FortschreitendeKultur;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: {
                eq: "Kachel_Fortschreitende-Kultur-3-e1494930741494.jpg"
            }
        ) {
            ...fluidImage
        }
    }
`;
